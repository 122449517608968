import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby"

const Consejos = () => {
  const consejospage = useStaticQuery(graphql`
    {
      allStrapiNoticiasConsejosTrucos(
        filter: {categoria_noticia: {nombre: {eq: "Consejos de salud"}}}
        sort: {order: DESC, fields: createdAt}
      ) {
        edges {
          node {
            id
            seo {
              shareImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 7, sizes: "1080")
                  }
                }
              }
            }
            slug
            strapiId
            subtitulo_home
            titulo
          }
        }
      }
    }
  `)
      return (  
      <div className="bg-white pb-8">
          <div className="mx-auto px-0 sm:px-0 lg:px-0">
          <div className="grid grid-cols-1">
              <ul role="list" className="sm:grid sm:grid-cols-2 list-none gap-6">
                  {consejospage.allStrapiNoticiasConsejosTrucos.edges.map((consejourl) => (
                  <li key={consejourl.node.strapiId} className="pb-0 col-span-1 first:col-span-2">
                    <Link to={`/consejos-de-salud/${consejourl.node.slug}`}>
                      <div className="items-center ">
                      <GatsbyImage
                          alt={`${consejourl.node.titulo}`}
                          image={
                            consejourl.node.seo.shareImage.localFile.childImageSharp
                          .gatsbyImageData
                          }
                          className="w-full object-cover aspect-w-6 aspect-h-4"
                      />
                      <div className="">
                          <h3 className="text-s18 leading-6 px-4 md:px-4 poppins w500 text-center mb-6 sm:mb-0 pt-4 bg-amarillotransparente h-28">{consejourl.node.titulo}</h3>
                      </div>
                      </div>
                    </Link>
                  </li>
                  ))}
              </ul>
          </div>
          </div>
      </div>
  ) 
}     

    export default Consejos;
