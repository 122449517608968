import React, {useEffect } from "react";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import Masvistos from "../components/masvistos";
import Consejos from "../components/consejos";
import Newsletter from "../components/newsletter";

const Consejossalud = () => {
    const seo = {
      metaTitle: "Nutrición a tu alcance",
      metaDescription: "Aprende todo lo que necesitas saber de nutrición para que puedas alimentarte mejor",
      shareImage: ""
    };


  return (
    <Layout seo={seo}>
        <div className="grid grid-cols-1 gap-4 md:gap-6 md:grid-cols-3 sm:px-6 lg:max-w-5xl lg:px-0 mx-auto">
            <div className="py-0  md:col-span-2 ...">
                <h1 className="max-w-3xl m-auto poppins px-4 pt-2 md:text-s38 text-s33 leading-8 md:leading-11 pb-6 text-black text-center">Consejos de salud y bienestar</h1>
                <Consejos />
            </div>
            <div className="px-4 md:pl-4 md:ml-2 md:border-l md:pr-10 md:mt-16">
                <div className="poppins font-medium text-25 sm:pt-2 leading-7 pb-2">Alimentos, nutrición, descanso y deporte</div>
                <div className="archebook text-20 text-gristexto">Son los 4 pilares de unos hábitos saludables. En esta sección puedes ampliar tus conocimientos para aplicar de manera sencilla en tu día a día. Sal de dudas que alimentos son buenos y cuáles no. Conoce a profundidad los alimentos que vas a comer.</div>
                <div className="text-marron poppins w500 bg-amarillo text-xl text-center py-3 mt-8">LO MÁS VISTO</div>
                <div className="pl-0 pr-10 md:pr-2">
                    <Masvistos/>
                </div>
            </div>
        </div>
        <Newsletter />
    </Layout>
  );
};

export default Consejossalud;